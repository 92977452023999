<template>
    <div>
        <div>
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--8">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-header border-0">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h3 class="mb-0">{{ tt('good_issued')}}</h3>
                                    </div>
                                    <div class="col">
                                        <base-button type="default" size="sm" class="float-right" @click="pivot()">{{ tt('pivot')}}</base-button>
                                    </div>
                                    <!-- <div class="col text-right">
                                        <base-button type="default" size="sm" @click="" :disabled="load.transaction_history">{{tt('save')}} <span v-if="load.transaction_history"><i class="fas fa-spinner fa-spin"></i></span></base-button>
                                    </div> -->
                                </div>
                            </div>
                            <div v-if="!table.load">
                                <div style="height:300px">
                                        <el-table class="table-responsive table" v-if="table.data.length > 0"  :data="table.data" header-row-class-name="thead-light" border height="300px">
                                            <el-table-column :label="tt('material_number')" sortable width="210px" prop="material_number"></el-table-column>
                                            <!-- <el-table-column :label="tt('sum_of_process')" sortable width="175px" prop="sum_of_process"></el-table-column> -->
                                            <el-table-column :label="tt('quantity')" sortable min-width="130px" prop="qty"></el-table-column>
                                            <el-table-column :label="tt('month')" sortable min-width="125px" prop="month"></el-table-column>
                                            <el-table-column :label="tt('year')" sortable min-width="125px" prop="year"></el-table-column>
                                        </el-table>
                                        <div class="text-center" style="height: 100px" v-else>
                                            <br><br>
                                            <img src="/assets/smartcat/data_is_empty.png" alt="Data Is Empty" width="350px">
                                            <p class="text-muted">{{ tt('empty') }}</p>
                                        </div>
                                </div>
                                <div class="card-footer pb-0 ">   
                                        <span class="float-left">
                                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                                        </span>                             
                                        <span class="float-right">
                                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.page.total}) }}
                                        </span>
                                </div>
                            </div>
                            <skeleton-loading v-else></skeleton-loading>
                        </div>
                    </div>
                </div>
                 
            </div>
        </div>
    </div>
</template>


<script>
import Api from '@/helpers/api';
import GoodIssuedService from '@/services/rcs/goodIssued.service'
export default {
    data(){
        return{
            table:{
                data:[],
                load:false,
                page:{}
            },
        }
    },
    mounted(){
        this.get()
    },
    methods:{
        get(){
            let context = this;
            this.table.load = true
            Api(context, GoodIssuedService.get()).onSuccess((response) => {
                context.table.data = response.data.data.data;
                context.table.page = response.data.data;
            }).onError((err) =>{
                context.table.data = []
            }).onFinish(() => {
                context.table.load = false
            }).call()
        },
        pivot(){
            this.$router.replace({ name :'Good Issued Pivot'})
        }
        
        
    }
}
</script>